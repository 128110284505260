export const DISCOVERY_CAMPS_SITE_IDS = [
  1712,
  1719,
  1720,
  1721,
  1723,
  1724,
  1725,
  1726,
  1727,
  1728,
  1737,
  1738,
  1739,
];

export const DISCOVERY_CAMPS_ORG_IDS = [1669, 1674, 1675];

export const DISCOVERY_CAMPS_IDS = DISCOVERY_CAMPS_SITE_IDS.concat(
  DISCOVERY_CAMPS_ORG_IDS
);

const discoveryCampsBaseConfigs = [
  {
    duration: 15000,
    id: "campsCarbonCommitment-static",
    title: "Carbon Commitment Statement",
  },
  {
    duration: 15000,
    id: "campsEnergySavingTips-static",
    title: "Energy Saving Tips",
  },
];

export const discoveryCampsSiteConfigs = discoveryCampsBaseConfigs;
// export const discoveryCampsSiteConfigs = discoveryCampsBaseConfigs.concat({
//   duration: 15000,
//   id: "submetersanalysis-daily",
//   title: "Sub Meter Breakdown - Today ($TODAY)",
// });

export const discoveryCampsOrgConfigs = discoveryCampsBaseConfigs;
